import React from 'react'
import ContentBlock from 'components/contentBlock'
import { trFunction } from 'utils/functions'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  const collaborations = data.collaborations
  const collaborationBlocks = collaborations.edges.map((collaboration) => {
    return {
      title: collaboration.node.title,
      subtitle: collaboration.node.designedBy,
      imagesFullLink: `/collaborations/${collaboration.node.slug}`,
      template: 'full-images',
      textPosition: 'bottom left',
      imagesFull: [collaboration.node.imageMain],
      imagesFullMobile: [collaboration.node.imageMainMobile],
    }
  })

  return (
    <Layout>
      <div id="content">
        {page.contentBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
        {collaborationBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "collaborations" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        video {
          wistiaCode
          optimizedFiles {
            file {
              url
              fileName
            }
          }
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
    collaborations: allContentfulCollaborations(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          order
          title
          slug
          designedBy
          imageMain {
            ...GatsbyImage
          }
          imageMainMobile {
            ...GatsbyImage
          }
        }
      }
    }
  }
`
